import type { PaletteColor, Theme, UseMediaQueryOptions } from '@mui/material'
import { alpha, useMediaQuery as useMediaQueryMui, useTheme } from '@mui/material'
import { get, isObject } from 'lodash'
import { useMemo } from 'react'

/**
 * Basic wrapper around MUI's `useMediaQuery` hook that provides the theme type
 */
export function useMediaQuery(queryInput: string | ((theme: Theme) => string), options?: UseMediaQueryOptions) {
  return useMediaQueryMui(queryInput, options)
}

/**
 * Checks if the color argument is a theme value and returns the corresponding color from the
 * palette. Otherwise, returns the color argument unchanged.
 */
export function usePaletteColor(path: string, opacity?: number): string {
  const { palette } = useTheme()
  return useMemo(() => {
    const colorObj = get(palette, path)
    const color = isObject(colorObj) ? (colorObj as PaletteColor).main : typeof colorObj === 'string' ? colorObj : path

    if (typeof opacity === 'undefined') return color
    try {
      // This can fail if the input color is not a valid color string
      return alpha(color, opacity)
    } catch {
      return color
    }
  }, [palette, path, opacity])
}

export function getScrollAreaStyles(side: 'left' | 'right', canScroll: boolean) {
  const commonStyles = {
    // Required for ::before and ::after pseudo-selectors to work
    content: '""',

    // Take up entire height of the table (including headers but excluding the footer)
    position: 'absolute',
    top: 0,
    bottom: 0,

    // Ensure it renders on top of the header row
    zIndex: 2,

    // Gradient which is transparent on the inner side and white on the outer side
    background: `linear-gradient(${side === 'left' ? 90 : 270}deg, white 0%, rgba(255, 255, 255, 0.00) 38.89%)`,
    width: 150,

    // Fade in and out when user scrolls horizontally
    opacity: canScroll ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',

    // Pass all mouse events through, down to the table content
    pointerEvents: 'none'
  }

  return side === 'left' ? { ...commonStyles, left: 0 } : { ...commonStyles, right: 0 }
}
